<mat-progress-bar mode="indeterminate" class="m-0 p-0" [hidden]="!isProgressBarOn"></mat-progress-bar>
<mat-sidenav-container class="container">
  <mat-sidenav #sidenav mode="over" class="burgundy leftMenu" style="opacity: 0.8;">
    <!-- <span style="background-color: rgba(42,1,8,0.75);"> -->
    <button mat-button (click)="sidenav.toggle()" style="float: left;">
      <!-- <mat-icon>menu</mat-icon> -->
      <img src="../../../../../assets/retailer-portal/icon-button-menu.svg" height="30px" />
    </button>
    <div routerLink="/home" (click)="sidenav.toggle()">
      <img src="../../../../../assets/retailer-portal/logo-sign-in.svg" height="50px" />
      &nbsp;&nbsp;
      <img src="../../../../../assets/retailer-portal/nav-dashboard.svg" height="40px" />
    </div>
    <!-- </span> -->

    <ul class="menu">
      <ng-container *ngFor="let tile of tiles">
        <li *ngIf="!tile?.newWindow" class="liStyle" [routerLink]="[tile?.url, currentLocationUid]"
          (click)="sidenav.toggle()">
          <div class="row">
            <div class="col-2 float-left">
              <img src="{{ tile?.sideBarSvg }}" height="30px" />
            </div>
            <div class="col float-left">
              <span class="liText">{{ tile?.title }}</span>
            </div>

          </div>
        </li>
        <li *ngIf="!(tile?.url === '/analytics' && isPlantAdmin) && tile?.newWindow" class="liStyle"
          (click)="sidenav.toggle()">
          <a style="text-decoration: none;" href={{tile?.url}} target="_blank">
            <img src="{{ tile?.sideBarSvg }}" height="30px" />
            &nbsp;&nbsp;
            <span class="liText">{{ tile?.title }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </mat-sidenav>

  <!-- /// Right NavBar ///-->
  <mat-sidenav #sidenavRight position="end" mode="over" class="rightMenu orange" style="opacity: 0.8;">
    <div mat-button (click)="sidenavRight.toggle()" style="background-color: #fb9624;">
      <div class="row">
        <div class="col-sm-8">
          <!-- <span> -->
          <span style="color: white;" *ngIf="(userProf !== null && userProf !== undefined)">{{
            userProf.userProfFirstName }}
            {{ userProf.userProfLastName }}
          </span>
          <br />
          <span> {{ currentLocation }}</span>
          <!-- </span> -->
        </div>
        <div class="col-sm-4">
          <img src="../../../../../assets/retailer-portal/icon-button-user.svg" height="30px" />
          <!-- <img src="../../../../../assets/retailer-portal/username.svg" height="30px" /> -->
        </div>
      </div>
    </div>

    <ul *ngIf="showSwitchLocation; else switchLocationElse" class="ulMenuRight" style="margin-top: 50px;">
      <li class="liStyleRight" routerLink="/admin" (click)="sidenavRight.toggle()" *ngIf="isSuperAdmin">
        <img src="../../../../../assets/retailer-portal/admin_panel_settings-black-48dp.svg" height="35px" />
        &nbsp;&nbsp;
        <span class="liTextRight">Admin</span>
      </li>
      <li class="liStyleRight" routerLink="/home" (click)="sidenavRight.toggle()">
        <img src="../../../../../assets/retailer-portal/user-menu-dashboard.svg" height="30px" />
        &nbsp;&nbsp;
        <span class="liTextRight">Dashboard</span>
      </li>
      <a style="text-decoration: none;" (click)="sidenavRight.toggle()" *ngIf="userProf?.accountType === 'WMT'"
        href="https://admin.cavco.com/user_profile/authprofile.do?uid={{userProf?.userProfUid}}&method=loadEditProfile"
        target="_blank">
        <li class="liStyleRight">
          <img src="../../../../../assets/retailer-portal/user-menu-settings.svg" height="30px" />
          &nbsp;&nbsp;
          <span class="liTextRight">Settings</span>
        </li>
      </a>
      <li class="liStyleRight" (click)="showSwitchLocation = !showSwitchLocation">
        <img src="../../../../../assets/retailer-portal/user-menu-switch-locations.svg" height="30px" />
        &nbsp;&nbsp;
        <span class="liTextRight">Switch Location</span>
        &nbsp;&nbsp;
        <span style="font-size: 20px; font-weight: 600;">></span>
      </li>
      <li class="liStyleRight" routerLink="/help" (click)="sidenavRight.toggle()">
        <img src="../../../../../assets/retailer-portal/user-menu-help.svg" height="30px" />
        &nbsp;&nbsp;
        <span class="liTextRight">Help</span>
      </li>
      <li class="liStyleRight" (click)="sidenavRight.toggle(); signOut()">
        <img src="../../../../../assets/retailer-portal/user-menu-sign-out.svg" height="30px" />
        &nbsp;&nbsp;
        <span class="liTextRight">Sign Out</span>
      </li>
    </ul>
    <!-- //else// -->
    <ng-template #switchLocationElse>
      <ul class="ulMenuRight" style="margin-top: 50px;">
        <li class="liStyleRight" (click)="showSwitchLocation = !showSwitchLocation">
          <span style="font-size: 20px; font-weight: 600;">&lt;</span>
          &nbsp;&nbsp;
          <img src="../../../../../assets/retailer-portal/user-menu-switch-locations.svg" height="30px" />
          &nbsp;&nbsp;
          <span class="liTextRight">Switch Location</span>
        </li>

        <li class="liStyleRight" style="font-weight: 500; font-size: 16px; text-align: center;"
          *ngFor="let location of locations" (click)="
            changeLocation(location);
            sidenavRight.toggle();
            showSwitchLocation = !showSwitchLocation
          ">
          {{ location.locationDba }}
        </li>
      </ul>
    </ng-template>
  </mat-sidenav>

  <!-- Main page navbar navbar-fixed-top    mat-toolbar     position: fixed;-->
  <mat-sidenav-content style="min-height: 700px;">
    <div class="navbar navbar-fixed-top orange" style="margin-bottom: 40px;"
      *ngIf="(userProf !== null && userProf !== undefined) && !isSignInPage">
      <div style="float: left;">
        <button mat-button (click)="sidenav.toggle()">
          <!-- <mat-icon height="50px">menu</mat-icon> -->
          <img src="../../../../../assets/retailer-portal/icon-button-menu.svg" height="30px" />
        </button>

        &nbsp;
        <span class="logoTitle">
          <span *ngIf="isHomeTitle; else otherTitles">
            <img class="logoText" src="../../../../../assets/retailer-portal/logo-header.svg" height="50px" />
            &nbsp;&nbsp;&nbsp;
            <span class="logoText">CAVConnect Dashboard</span>
          </span>
          <ng-template #otherTitles>
            <span style="font-size: 30px; font-weight: 500;">{{ title }}</span>
          </ng-template>
        </span>
      </div>

      <div style="float: right;">
        <span class="locationOptions" style="font-size: 12px;">
          <span class="dropdown">
            <a class="dropbutton">
              {{ currentLocation }}
              <img src="../../../assets/retailer-portal/arrow_down-24px.svg" alt="arrow down" />
            </a>
            <ul class="dropdown-content" *ngIf="showDropDown">
              <li (click)="changeLocation(location)" *ngFor="let location of locations">
                {{ location.locationDba }}
              </li>
            </ul>
          </span>
        </span>
        <span class="showUserName">
          | &nbsp;
          <span class="userName" (click)="sidenavRight.toggle()">Hello {{ userProf.userProfFirstName }}</span>
        </span>

        <button mat-button (click)="sidenavRight.toggle()">
          <img src="../../../../../assets/retailer-portal/icon-button-user.svg" height="30px" />
        </button>
      </div>
    </div>

    <router-outlet (activate)="onActivate($event)">
      <span (click)="goUp()" id="buttonUp">
        <img src="../../../../../assets/retailer-portal/button-up.svg" height="40px" style="color: gray;" />
        <h4 style="text-align: center; margin-bottom: 0px;">TOP</h4>
      </span>
    </router-outlet>

    <footer class="footer footer__splash">
      <p>CAVConnect <span class="o-divider">|</span> Copyright © 2020 <strong>Cavco Industries, Inc.</strong> All rights
        reserved.</p>
    </footer>

  </mat-sidenav-content>
</mat-sidenav-container>