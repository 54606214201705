import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UserProf } from '../../model/interfaces/User.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfService {
  userProfSignedIn: Subject<boolean> = new Subject<boolean>();
  $userProfInfo: Subject<UserProf> = new Subject<UserProf>();

  url = environment.url + '/user/';

  constructor(private httpClient: HttpClient) {}

  getUserInfo(userCredential): Observable<any> {
    const url = this.url + 'usercredential';
    return this.httpClient.post<any>(url, userCredential);
  }
}
