import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  url = environment.url + '/location/';

  constructor(private httpClient: HttpClient) {}

  getLocations(userProfileUID: number): Observable<any> {
    const url = this.url + userProfileUID;
    return this.httpClient.get<Location>(url);
  }

  getPlantAdminLocations(userProfileUID: number): Observable<any> {
    const url = this.url + 'plant-admin/' + userProfileUID;
    return this.httpClient.get<Location>(url);
  }
}
