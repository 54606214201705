import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (localStorage.getItem('userProf')) {
      let authHeader =
        'Bearer ' + JSON.parse(localStorage.getItem('userProf')).jwt;
      req = req.clone({
        setHeaders: {
          Authorization: authHeader,
        },
      });
    }
    return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // let asd = err.error.message.includes('JWT expired');
    // console.log('err.message.includes(JWT expire) : ', asd);
    // console.log('err', err);

    if (
      // err.status === 401 ||
      err.status === 403 ||
      (err.status === 500 && err.error.message.includes('JWT expired'))
    ) {
      this.notificationService.showWarning(
        'Please sign in again. Thank you.',
        'Session timed out.'
      );
      localStorage.clear();
      this.router.navigateByUrl(`/sign-in`);
      return of(err.message);
    }
    return throwError(err);
  }
}
