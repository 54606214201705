import { LoginAttemptService } from './../../shared/services/api/login-attempt.service';
import { UserRoleService } from './../../shared/services/api/user-role.service';
import { TileService } from './../../shared/services/api/tile.service';
import { NotificationService } from './../../shared/services/helper/notification.service';
import { Location } from '@angular/common';
import { LocationInfoService } from './../../shared/services/location-info.service';
import { LocationService } from '../../shared/services/api/location.service';
import { UserProf } from './../../shared/model/interfaces/User.model';
import { Component, OnInit, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import { UserInfo } from 'os';
import { UserProfService } from 'src/app/shared/services/api/user-prof.service';
import { Subscription } from 'rxjs';
import { DTile } from 'src/app/shared/model/interfaces/DTile.model';

@Component({
  selector: 'app-rp-header',
  templateUrl: './rp-header.component.html',
  styleUrls: ['./rp-header.component.css'],
})
export class RpHeaderComponent implements OnInit, OnDestroy {
  private currentLocationSubscription: Subscription;
  private isProgressBarOnSubscription: Subscription;
  // private userProfSubscription: Subscription;
  private userProfInfoSubscription: Subscription;
  private locationsSubscription: Subscription;
  title: string;
  // isHomeTitle: boolean = true;
  isHomeTitle: boolean;
  showSwitchLocation = true;
  userProf: UserProf;
  locations: Location[];
  currentLocation: string;
  location = 'nothing';
  signedIn: boolean;
  tiles: DTile[];
  currentLocationUid: number;
  // accessGranted: boolean;
  isSignInPage: boolean;
  isProgressBarOn: boolean = false;
  isSuperAdmin: boolean = false;
  isPlantAdmin: boolean = false;
  showDropDown: boolean = true;

  constructor(
    private router: Router,
    private userProfService: UserProfService,
    private locationService: LocationService,
    private locationInfoService: LocationInfoService,
    private notificationService: NotificationService,
    private tileService: TileService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private userRoleService: UserRoleService,
    private loginAttemptService: LoginAttemptService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      const currentURL = params['locationUid'];
    });

    if (JSON.parse(
      localStorage.getItem('selectedLocation')
    ) !== null) {
      this.currentLocationUid = JSON.parse(
        localStorage.getItem('selectedLocation')
      ).locationUid;
    } else {
      setTimeout(() => {
        this.currentLocationUid = JSON.parse(
          localStorage.getItem('selectedLocation')
        )?.locationUid;
      }, 1000);
    }

    if (JSON.parse(localStorage.getItem('tiles - ')) !== null) {
      this.tiles = JSON.parse(localStorage.getItem('tiles - '));
    }

    if (localStorage.getItem('userProf')) {
      this.getTiles();
      this.checkRole();
    }

    this.userProfInfoSubscription = this.userProfService.$userProfInfo.subscribe(
      (userProf) => {
        this.userProf = userProf;
        this.getTiles();
      }
    );

    this.currentLocationSubscription = this.locationInfoService.currentLocation.subscribe(
      (currentLocation) => {
        this.currentLocation = currentLocation;

        setTimeout(() => {
          this.currentLocationUid = JSON.parse(
            localStorage.getItem('selectedLocation')
          ).locationUid;
        }, 100);
      }
    );
    this.locationsSubscription = this.locationInfoService.$locations.subscribe(
      (locations) => {
        this.locations = locations;
      }
    );

    this.userProf = JSON.parse(localStorage.getItem('userProf'));
    if (this.userProf) {

      this.getTiles();
    }
  }

  checkRole() {
    this.isPlantAdmin = JSON.parse(localStorage.getItem('userProf')).userProfRoleName.includes('Retailer_Portal_Leads_Plant_Manager');
    this.userRoleService.isPlantAdminRole().subscribe(
      (response) => {
        this.isPlantAdmin = response.body;
        this.getLocations();
      },
      (error) => {
        console.log('error isPlantAdmin : ', error)
      }
    );

    this.userRoleService.hasBannerAccess().subscribe(
      (response) => {
        this.isSuperAdmin = response.body;
      },
      (error) => {
        console.error('isSuperAdmin Error: ', error);
      }
    )
  }

  getLocations() {
    let callThis = null;
    if (this.isPlantAdmin) {
      callThis = this.locationService.getPlantAdminLocations(this.userProf.userProfUid)
      // callThis = this.locationService.getPlantAdminLocations(46812)
    } else {
      callThis = this.locationService.getLocations(this.userProf.userProfUid)
    }
    callThis.subscribe(
      (data) => {
        this.locations = data.body;
        this.currentLocation = JSON.parse(
          localStorage.getItem('selectedLocation')
        ).locationDba;
        // this.currentLocation = data.body[0].locationDba;
      },
      (error) => {
        console.log('Location retrieving error ' + error.message);
      }
    );
  }

  ngAfterContentChecked() {
    this.isProgressBarOnSubscription = this.locationInfoService.isProgressBarOn.subscribe(
      (isProgressBarOn) => {
        this.isProgressBarOn = isProgressBarOn;
      }
    );

    this.changeDetectorRef.detectChanges();
  }


  getTiles() {
    this.tileService.getAllTiles().subscribe(
      (response) => {
        this.tiles = response.body;
        if (response.body !== undefined) {
          localStorage.setItem('tiles - ', JSON.stringify(this.tiles));
        }
      },
      (error) => {
        console.log('error in retrieving tiles header: ', error.message);
      }
    );
  }

  changeLocation(location) {
    this.showDropDown = false;
    this.locationInfoService.changeLocation(location);

    setTimeout(() => {
      this.currentLocationUid = JSON.parse(
        localStorage.getItem('selectedLocation')
      ).locationUid;
      this.showDropDown = true;
    }, 100);
  }

  onActivate(event) {
    this.isSignInPage = false;
    switch (event.constructor.name) {
      case 'RpBodyComponent': {
        this.isHomeTitle = true;
        break;
      }
      case 'PageQuotesComponent': {
        this.title = 'Quotes';
        this.isHomeTitle = false;
        break;
      }
      case 'PageQuoteViewComponent': {
        this.title = 'Quote';
        this.isHomeTitle = false;
        break;
      }
      case 'PageSalesOrdersComponent': {
        this.title = 'Sales Orders';
        this.isHomeTitle = false;
        break;
      }
      case 'PageSalesOrdersViewComponent': {
        this.title = 'Sales Order';
        this.isHomeTitle = false;
        break;
      }
      case 'PageWebLeadsComponent': {
        this.title = 'Web Leads';
        this.isHomeTitle = false;
        break;
      }
      case 'PageCareTracComponent': {
        this.title = 'CareTrac';
        this.isHomeTitle = false;
        break;
      }
      case 'PageWebLeadsViewComponent': {
        this.title = 'Web Lead';
        this.isHomeTitle = false;
        break;
      }
      case 'PageAnalyticsComponent': {
        this.title = 'Website Analytics';
        this.isHomeTitle = false;
        break;
      }
      case 'PageAnalyticsViewComponent': {
        this.title = 'Website Analytics';
        this.isHomeTitle = false;
        break;
      }
      case 'SignInComponent': {
        this.isSignInPage = true;
        break;
      }
      default: {
        this.isHomeTitle = true;
        break;
      }
    }


  }

  @HostListener('window:scroll', []) onWindowScroll() {
    this.scrollFunction();
  }

  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('buttonUp').style.display = 'block';
    } else {
      document.getElementById('buttonUp').style.display = 'none';
    }
  }

  goUp() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  signOut() {
    this.loginAttemptService.logout(this.userProf.jwt).subscribe(
      (response) => {
        // console.log('logout response : ', response);
      },(error) => {
        console.log('logout error : ', error);
      }
    )


    localStorage.clear();
    this.notificationService.showSuccess(
      'You have Successfully Signed Out',
      'Sign Out'
    );
    this.userProf = null;
    this.router.navigate(['/sign-in']);
  }

  ngOnDestroy() {
    this.currentLocationSubscription.unsubscribe();
    this.isProgressBarOnSubscription.unsubscribe();
    // this.userProfSubscription.unsubscribe();
    this.userProfInfoSubscription.unsubscribe();
    this.locationsSubscription.unsubscribe();
  }


}
