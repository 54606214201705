import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url = environment.url + '/user-role/';

  constructor(private httpClient: HttpClient) {}

  isPlantAdminRole(): Observable<any> {
    const url = this.url + 'is-plant-admin-role';
    return this.httpClient.get<any>(url);
  }
}
