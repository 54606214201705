<div class="modal-body w-100">

    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">
            <img role="img" style="height: 38px;" src="../../../../assets/retailer-portal/kill.svg" />
        </span>
    </button>
    <br><br>
    <span class="h5 mb-3 mt-5">{{message}}</span>
    <br><br>
    <ng-container *ngIf="!confirmed">
        <select class="w-100" [(ngModel)]="selected" name="leadStatus">
            <option value="{{item}}" *ngFor="let item of list">{{item}}</option>
        </select>

        <div class="text-center mt-4 mb-3 float-right">
            <button type="submit" class="btn orange mr-2" (click)="confirmedSelection()">Assign</button>
            <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Cross click')"
                id="cancelButton">Cancel</button>
        </div>
    </ng-container>

    <ng-container *ngIf="confirmed">
        <div class="text-center mt-4 mb-3 float-right">
            <button type="submit" class="btn orange mr-2" (click)="assign()">OK</button>
            <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Cross click')"
                id="cancelButton">Cancel</button>
        </div>
    </ng-container>

</div>