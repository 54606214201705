<div class="modal-body">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">
        <img role="img" style="width:35px; height: 38px;" src="../../../../assets/retailer-portal/kill.svg" />
    </span>
  </button>
  <br>
  <br>
  <br>
  <h1 class="d-flex justify-content-center">{{ message }}</h1>
  <div class="text-center mt-4 mb-5">
    <button type="button" class="btn btn-light px-4 mr-4 btn-lg" (click)="activeModal.dismiss('Cross click')"
    id="cancelButton">
      Cancel
    </button>
  
    <button type="submit" class="btn orange px-4 btn-lg" id="deleteButton" (click)="deleteWebLead()">
      Delete
    </button>
  </div>
  
</div>
