<div class="modal-header orange text-dark">
  <h1 class="modal-title" id="modal-basic-title">
    Email
  </h1>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="options" class="pt-3">
    
    <label>To:</label> <br />
    <div *ngIf="optionsData.emailTo.invalid && (optionsData.emailTo.touched)" class="alert-danger">
      <span>Email is required. Please enter a valid email address.</span>
    </div>
    
    <div class="input-group mb-3">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text" id="emailTo">To...</span>
      </div> -->
      <input
        type="email"
        class="form-control"
        aria-label="emailTo"
        formControlName="emailTo"
        class="form-control"
      />
    </div>

    <label>Cc:</label> <br />
    <div class="input-group mb-3">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text" id="emailCc">Cc...</span>
      </div> -->
      <input
        type="email"
        class="form-control"
        aria-label="emailCc"
        formControlName="emailCc"
        multiple
      />
    </div>

    <label>Subject:</label> <br />
    <div
      *ngIf="
        optionsData.emailSubject.invalid &&
        (optionsData.emailSubject.dirty || optionsData.emailSubject.touched)
      "
      class="alert-danger"
    >
      <span>Email Subject is required</span>
    </div>
    <div class="input-group mb-4">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text" id="subject">Subject</span>
      </div> -->
      <input
        type="text"
        class="form-control"
        aria-label="subject"
        formControlName="emailSubject"
      />
    </div>

    <label>Body:</label> <br />
    <div class="input-group mb-4">
      <!-- <div class="input-group-prepend">
        <span class="input-group-text" id="body">Body</span>
      </div> -->
      <textarea
        type="text"
        class="form-control"
        aria-label="body"
        rows="3"
        formControlName="emailBody"
      ></textarea>
    </div>

    <div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">Include PDF Attachment: </label>
      </div>      
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="includeAttachment" formControlName="includeAttachment" [value]="true"  [checked]="true">
        <label class="form-check-label" for="yes">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="includeAttachment" formControlName="includeAttachment" [value]="false">
        <label class="form-check-label" for="no">No</label>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss('Cross click')"
  >
    Cancel
  </button>
  <!-- [disabled]="optionsData.emailSubject.invalid || optionsData.emailTo.invalid" -->
  <button type="submit" class="btn btn btn-success" [disabled]="optionsData.emailSubject.invalid || optionsData.emailTo.invalid" (click)="sendEmail()">
    Send
  </button>
</div>
<!-- (click)="modal.close('Save click')" -->
