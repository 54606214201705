<div class="body--dashboard">
  <main>

    <div class="container">
      <ng-container *ngFor="let bannerMessage of bannerMessages">
        <div class="row alertMessage" style="background-color: #2A0108;">
          <div class="col-sm-2">
            <img src="../../../../../assets/retailer-portal/warning.svg" height="50px"
              style="margin-top: 25px; margin-left: 20px;" />
          </div>
          <div class="col-sm-10">
            <h2 style="color:yellow; margin-top: 8px;">{{ bannerMessage?.title }}</h2>
            <h3 style="color:whitesmoke;">{{ bannerMessage?.body }} </h3>
          </div>
        </div>
      </ng-container>
      <div class="row pb-4">
        <ng-container *ngFor="let tile of tiles">
          <div class="col-sm-3 icon-bar">
            <ng-container *ngIf="tile?.newWindow">
              <a style="text-decoration: none; color: black; padding: 10px; padding-top: 0px;" href={{tile?.url}}
                target="_blank">
                <svg class="svg">
                  <defs>
                    <pattern [attr.id]="tile?.svgId" height="100%" width="100%">
                      <rect height="100%" width="100%" fill="white" />
                      <image [attr.xlink:href]="tile?.svgHref" height="50px" width="100" x="-10" [attr.y]="tile?.svgY">
                      </image>
                    </pattern>
                  </defs>
                  <circle cx="45" cy="50" r="40" [attr.fill]="tile?.fill"></circle>
                </svg>
                <h3>{{tile?.title}}</h3>
              </a>
            </ng-container>
            <div *ngIf="!tile?.newWindow" [routerLink]="[tile?.url, currentLocationUid]">
              <svg class="svg">
                <defs>
                  <pattern [attr.id]="tile?.svgId" height="100%" width="100%">
                    <rect height="100%" width="100%" fill="white" />
                    <image [attr.xlink:href]="tile?.svgHref" height="50px" width="100" x="-10" [attr.y]="tile?.svgY">
                    </image>
                  </pattern>
                </defs>
                <circle cx="45" cy="50" r="40" [attr.fill]="tile?.fill"></circle>
              </svg>
              <h3>{{tile?.title}}</h3>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </main>
</div>