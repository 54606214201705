import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Email } from 'src/app/shared/model/interfaces/Email.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {
  @Input() public emailModal: Email;
  options: FormGroup;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {
    
  }

  ngOnInit(): void {
    const subject: string = this.emailModal?.emailSubject;
    const ebody: string = this.emailModal?.emailBody;
    this.options = this.fb.group({
      emailTo: ['', [Validators.email, Validators.required]],
      emailCc: ['', Validators.email],
      emailSubject: [subject, Validators.required],
      emailBody: [ebody],
      attachmentName: [''],
      attachmentBase64: [''],
      includeAttachment: [true],
    });
  }

  get optionsData() { return this.options.controls; }

  sendEmail() {
    let email: Email = {
      emailTo: this.options.controls.emailTo.value,
      emailCc: this.options.controls.emailCc.value,
      emailSubject: this.options.controls.emailSubject.value,
      emailBody: this.options.controls.emailBody.value,
      attachmentName: this.options.controls.attachmentName.value,
      attachmentBase64: this.options.controls.attachmentBase64.value,
      includeAttachment: this.options.controls.includeAttachment.value,
    }
    
    this.activeModal.close(email);
  }
}
