import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  url = environment.url + '/user-role';

  constructor(private httpClient: HttpClient) { }

  getAllRtlUserRoles(): Observable<any> {
    const url = this.url + '/all-retailers-roles';
    return this.httpClient.get<any>(url);
  }

  getAllRolesWithBannerAccess(): Observable<any> {
    const url = this.url + '/all-banner-roles';
    return this.httpClient.get<any>(url);
  }


  hasBannerAccess(): Observable<any> {
    const url = this.url + '/banner-admin-access';
    return this.httpClient.get<any>(url);
  }

  hasBannerAccessWithPipe(): Observable<boolean> {
    const url = this.url + '/banner-admin-access';
    return this.httpClient.get<any>(url).pipe(map((response) => {
      return response.body;
    }));
  }

  hasRetailersAccess(): Observable<boolean> {
    const url = this.url + '/user-role-has-access';
    return this.httpClient.get<any>(url).pipe(map((response) => {
      return response.body;
    }));
  }

  isPlantAdminRole(): Observable<any> {
    const url = this.url + '/is-plant-admin-role';
    return this.httpClient.get<any>(url);
  }

}
