import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromWebsite'
})
export class FromWebsitePipe implements PipeTransform {

  transform(str: string): any {
    if(!str) return str
    if(str.includes('//www.')){
      return str.split('//www.').pop().split('/')[0];
    } else if(str.includes('https://')){
      return str.split('https://').pop().split('/')[0];
    } else if(str.includes('localhost')) {
      return str.substring(0, 21);
    }
    return str;
  }

}
