import { Injectable, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocationInfoService {
  // location = new Subject<Location>();

  constructor(private router: Router) { }

  $locations = new Subject<Location[]>();
  currentLocation = new Subject<string>();
  isProgressBarOn = new Subject<boolean>();

  changeLocation(location) {
    this.currentLocation.next(location.locationDba);
    localStorage.setItem('selectedLocation', JSON.stringify(location));

    setTimeout(() => {
      const currentLocationUid = JSON.parse(
        localStorage.getItem('selectedLocation')
      ).locationUid;
      if (this.router.url.substring(1, 10) === 'web-leads') {
        this.router.navigate(['/web-leads', currentLocationUid]);
      } else if (this.router.url.substring(1, 10) === 'analytics') {
        this.router.navigate(['/analytics', currentLocationUid]);
      } else if (this.router.url.substring(1, 7) === 'quotes') {
        this.router.navigate(['/quotes', currentLocationUid]);
      } else if (this.router.url.substring(1, 13) === 'sales-orders') {
        this.router.navigate(['/sales-orders', currentLocationUid]);
      }
    }, 100);
  }

  setCurrentLocation(currentLocationUid: number) {
    const locationsFullInfo = JSON.parse(
      localStorage.getItem('locationsFullInfo')
    );

    for (var i = 0; i < locationsFullInfo.length; i++) {
      if (Number(currentLocationUid) === locationsFullInfo[i].locationUid) {
        this.currentLocation.next(locationsFullInfo[i].locationDba);
        localStorage.setItem('selectedLocation', JSON.stringify(locationsFullInfo[i]));
        break;
      }
    }
  }

}
