import { SharedMaterialModule } from './shared/shared-material-module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RpBodyComponent } from './page-home/rp-body/rp-body.component';
import { RpHeaderComponent } from './page-home/rp-header/rp-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSliderModule } from '@angular/material/slider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailComponent } from './features/email/email.component';
import { AuthHttpInterceptorService } from './shared/services/helper/auth-http-interceptor.service';
import { AlertModalComponent } from './features/sharedFeaure/alert-modal/alert-modal.component';
import { FromWebsitePipe } from './shared/services/pipe/from-website.pipe';
import { ConfirmationComponent } from './features/sharedFeaure/confirmation/confirmation.component';
import { OptionsSelectionComponent } from './features/sharedFeaure/options-selection/options-selection.component';
import { HelpComponent } from './features/help/help.component';




@NgModule({
  declarations: [
    AppComponent,
    RpBodyComponent,
    RpHeaderComponent,
    EmailComponent,
    AlertModalComponent,
    ConfirmationComponent,
    OptionsSelectionComponent,
    HelpComponent,
    // SumPipe
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    FormsModule,
    SharedMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgbModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorService,
      multi: true
    },
    FromWebsitePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
