import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BannerMessage } from '../../model/interfaces/BannerMessage.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerMessageService {

  url = environment.url + '/banner-message/';

  constructor(private httpClient: HttpClient) {}

  getBannerMessageById(id: number): Observable<any> {
    const url = this.url + 'admin/getById/'+ id;
    // const url = this.url + id;
    return this.httpClient.get<BannerMessage>(url);
  }

  getAllShowBanners() {
    const url = this.url + 'all-show';
    return this.httpClient.get<any>(url);
  }

  getAllBanners() {
    const url = this.url + 'admin/all';
    return this.httpClient.get<any>(url);
  }

  addBanner(banner: BannerMessage): Observable<any> {
    const url = this.url + 'admin/add';
    return this.httpClient.post<any>(url, banner);
  }

  updateBanner(banner: BannerMessage): Observable<any> {
    const url = this.url + 'admin/update';
    return this.httpClient.put<BannerMessage>(url, banner);
  }

  deleteBanner(id: number): Observable<any> {
    const url = this.url + 'admin/delete/' + id;
    return this.httpClient.delete<BannerMessage>(url);
  }

  updateShowBanner(id: number): Observable<any> {
    const url = this.url + 'admin/update/show/' + id;
    return this.httpClient.put<BannerMessage>(url, null);
  }

}
