import { AssignTo } from './../../../shared/model/interfaces/AssignTo.model';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
  selector: 'app-options-selection',
  templateUrl: './options-selection.component.html',
  styleUrls: ['./options-selection.component.css']
})
export class OptionsSelectionComponent implements OnInit {

  @Input() message: string;
  @Input() userName: string;
  @Input() list: string[];
  @Input() selected: string;

  confirmed: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  assign() {
    this.activeModal.close(this.selected);
  }

  confirmedSelection() {
    this.confirmed = !this.confirmed;
    if(this.selected){
      this.message = 'Assign ' + this.userName + ' to ' + this.selected + '?';
    } else {
      this.message = 'Remove location for ' + this.userName + ' ?';
    }
    
  }

}
