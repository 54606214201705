import { TileService } from './../../shared/services/api/tile.service';
import { BannerMessage } from './../../shared/model/interfaces/BannerMessage.model';
import { BannerMessageService } from './../../shared/services/api/banner-message.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocationInfoService } from 'src/app/shared/services/location-info.service';
import { DTile } from 'src/app/shared/model/interfaces/DTile.model';
import { RoleService } from 'src/app/shared/services/api/role.service';

@Component({
  selector: 'app-rp-body',
  templateUrl: './rp-body.component.html',
  styleUrls: ['./rp-body.component.css'],
})
export class RpBodyComponent implements OnInit {
  private currentLocationSubscription: Subscription;
  currentLocationUid: number;
  bannerMessages: BannerMessage[] = [];
  bannerMessage: BannerMessage;
  tiles: DTile[];
  isPlantAdmin: boolean = false;

  constructor(
    private bannerMessageService: BannerMessageService,
    private tileService: TileService,
    private locationInfoService: LocationInfoService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    
    this.currentLocationSubscription = this.locationInfoService.currentLocation.subscribe(
      (currentLocation) => {
        // this.currentLocation = currentLocation;
        
          setTimeout(() => {
            this.currentLocationUid = JSON.parse(
              localStorage.getItem('selectedLocation')
            ).locationUid;
          }, 100);
      }
    );

    if(JSON.parse(
      localStorage.getItem('selectedLocation')
    ) !== null){          
        this.currentLocationUid = JSON.parse(
          localStorage.getItem('selectedLocation')
        ).locationUid;
    }else{
      setTimeout(() => {
        this.currentLocationUid = JSON.parse(
          localStorage.getItem('selectedLocation')
        ).locationUid;
      }, 2000);
    }

    if (JSON.parse(localStorage.getItem('tiles - ')) !== null) {
      this.tiles = JSON.parse(localStorage.getItem('tiles - '));
    }
    this.getAllTiles();
    this.getAllShowBanners();
    this.checkRole()
  }

  getAllTiles() {
    this.tileService.getAllTiles().subscribe(
      (response) => {
        this.tiles = response.body;
        if (response.body !== undefined) {
          localStorage.setItem('tiles - ', JSON.stringify(this.tiles));
        }
      },
      (error) => {
        console.log('error in retrieving tiles : ', error.message);
      }
    );
  }

  getAllShowBanners() {
    this.bannerMessageService.getAllShowBanners().subscribe(
      (response) => {
        this.bannerMessages = response.body;
      },
      (error) => {
        console.log('error in retrieving this.bannerMessages : ', error.message);
      }
    );
  }

  checkRole() {
    this.isPlantAdmin = JSON.parse(localStorage.getItem('userProf')).userProfRoleName.includes('Retailer_Portal_Leads_Plant_Manager');
    this.roleService.isPlantAdminRole().subscribe(
      (response) => {
        this.isPlantAdmin = response.body;
      },
      (error) => {
        console.log('error isPlantAdmin : ', error)
      }
    );
  }

  ngOnDestroy() {
    this.currentLocationSubscription.unsubscribe();
  }
}
