import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoleService } from '../api/user-role.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private userRoleService: UserRoleService){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean> | Promise<boolean> | boolean {
            const userProf = JSON.parse(localStorage.getItem('userProf'));
            if (userProf == null || userProf === undefined){
                let gotoLink = window.location.href
                if( !gotoLink.includes('/sign-in')){
                    localStorage.setItem('goToLink', JSON.stringify(gotoLink));
                }
                
                this.router.navigate(['/sign-in']);
            }else{
                // return true
                return this.userRoleService.hasRetailersAccess().pipe(map((hasAccess: boolean) => { 
                    if(hasAccess){
                        return true; 
                    } else {
                        let gotoLink = window.location.href
                        if( !gotoLink.includes('/sign-in')){
                            localStorage.setItem('goToLink', JSON.stringify(gotoLink));
                        }
                        
                        this.router.navigate(['/sign-in']);
                    }            
                }));
            }
        }
}
