import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginAttemptService {

  url = environment.url + '/user-login/';

  constructor(private httpClient: HttpClient) {}


  updateUserNameAttempt(userName: string): Observable<any> {
    const url = this.url + 'username/' + userName;
    return this.httpClient.put<string>(url, null);
  }

  updateIpAddressAttempt(ipAddress: string): Observable<any> {
    const url = this.url + 'ipaddress/' + ipAddress;
    return this.httpClient.put<string>(url, null);
  }

  logout(token: string): Observable<any> {
    const url = this.url + 'logout/' + token;
    return this.httpClient.post<string>(url, null);
  }


}