import { UserRoleService } from './../api/user-role.service';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService implements CanActivate {

    constructor(private router: Router, private userRoleService: UserRoleService){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean> | Promise<boolean> | boolean {

            // const userProf = JSON.parse(localStorage.getItem('userProf'));
            // if (userProf?.userProfRoleName !== 'PH_Crp_L2_IT_Assoc'){

            //     this.router.navigate(['/sign-in']);
            // }else{
            //     return true;
            // }

            return this.userRoleService.hasBannerAccessWithPipe().pipe(map((hasAccess: boolean) => { 
                    if(hasAccess){
                        return true; 
                    } else {
                        this.router.navigate(['/home']);
                    }            
                }));
            
        }
}
