import { AuthGuardService } from './shared/services/guard/auth-guard.service';
import { SignInModule } from './features/sign-in/sign-in.module';
import { RpBodyComponent } from './page-home/rp-body/rp-body.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AdminGuardService } from './shared/services/guard/admin-guard.service';
import { HelpComponent } from './features/help/help.component';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuardService],
    component: RpBodyComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('../app/features/sign-in/sign-in.module').then(
        (mod) => mod.SignInModule
      ),
  },
  {
    path: 'help',
    canActivate: [AuthGuardService],
    component: HelpComponent,
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('../app/features/quotes/quotes.module').then(
        (mod) => mod.QuotesModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('../app/features/sales-orders/sales-orders.module').then(
        (mod) => mod.SalesOrdersModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('../app/features/web-leads/web-leads.module').then(
        (mod) => mod.WebLeadsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('../app/features/analytics/analytics.module').then(
        (mod) => mod.AnalyticsModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuardService, AdminGuardService],
    loadChildren: () =>
      import('../app/features/admin/admin.module').then(
        (mod) => mod.AdminModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuardService, AdminGuardService],
    loadChildren: () =>
      import('../app/features/care-trac/care-trac.module').then(
        (mod) => mod.CareTracModule
      ),
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
